import React, { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useParams } from 'react-router-dom';
import { authGetTokens, getName } from '../../services/api/name-verification-api.service';
import APIService from '../../services/api/api.service';
import { updateTokens } from '../../services/api/login.service';
import {Controller, useForm} from "react-hook-form";
import AnimatedErrorMsg from "../AuthPage/view/AnimatedErrorMsg";
import PrimaryButton from "../../components/PrimaryButton";

interface UserAuthProperties {
    onSuccessAuth: () => void;
}

const UserAuth: FC<UserAuthProperties> = ({ onSuccessAuth }) => {
    const [isLoading, setIsLoading] = useState(false);
    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        setError,
        formState: { errors },
        clearErrors
    } = useForm({
        defaultValues: {
            name: '',
            phoneNumber: '',
        },
    });
    const { user_id } = useParams();

    const auth = async () => {
        try {
            setIsLoading(true);
            const tokens = await authGetTokens(user_id!, { secret: process.env.REACT_APP_SECRET, name: getValues().name, phone_number: getValues().phoneNumber });

            APIService.setToken(tokens.access_token);
            updateTokens(tokens.access_token, tokens.refresh_token, 'user');
            onSuccessAuth();
            setIsLoading(false);
        } catch (e:any) {
            setError('phoneNumber', {
                type: 'manual',
                message: e.response.data.message,
            });
        }
    }

    const fetchName = async () => {
        const userData = await getName(user_id!);
        setValue('name', userData.first_name);
    }

    useEffect(() => {
        fetchName();
    }, []);

    return <div className={styles.body}>
        <div className={styles.container}>
            <span role="img" aria-label="wave" className={styles.icon}>👋</span>
            <h1 className={styles.title}>Welcome to Empower Nation!</h1>
            <p className={styles.subTitle}>
                Enter your name below, this is how{'\n'}you will be presented.
            </p>
            <Controller
                control={control}
                render={({ field }) => (
                    <>
                        <input
                            disabled={true}
                            value={field.value}
                            placeholder="James"
                            className={[
                                styles.input,
                                errors.name && styles.error,
                            ].join(' ')}
                        />
                        <AnimatedErrorMsg text={errors.name?.message} />
                    </>
                )}
                name="name"
                rules={{
                    required: 'Name is required',
                }}
            />
            <Controller
                control={control}
                render={({ field }) => (
                    <>
                        <input
                            {...field}
                            onChange={(e) => {
                                clearErrors();
                                setValue('phoneNumber', e.target.value);
                            }}
                            placeholder="Phone Number"
                            className={[
                                styles.input,
                                errors.phoneNumber && styles.error,
                            ].join(' ')}
                        />
                        <AnimatedErrorMsg text={errors.phoneNumber?.message} />
                    </>

                )}
                name="phoneNumber"
                rules={{
                    required: 'Phone Number is required',
                    pattern: {
                        value: /^\d+$/,
                        message: 'Phone Number must contain only digits',
                    },
                }}
            />
            <PrimaryButton
                disabled={isLoading}
                isLoading={isLoading}
                name={'Continue'}
                onClick={handleSubmit(auth)}
            />
        </div>
    </div>
}

export default UserAuth;
