import React, { FC, useEffect, useRef, useState } from 'react';
import { IMicrophoneAudioTrack, ICameraVideoTrack, getCameras, getMicrophones } from 'agora-rtc-sdk-ng/esm';
import styles from "../../pages/CallPermissionsPage/styles.module.scss";
import Webcam from "react-webcam";
import { ReactComponent as MicIcon } from "../../assets/svgIcons/micIcon.svg";
import { ReactComponent as CamIcon } from "../../assets/svgIcons/cameraIcon.svg";
import { ReactComponent as ChatIcon } from "../../assets/svgIcons/chatIcon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgIcons/settingsIcon.svg";
import { ReactComponent as SwitchCamera } from "../../assets/svgIcons/switchPreCallCameraIcon.svg";
import {Modal, PrimaryButton} from "../index";
import APIService from "../../services/api/api.service";
import { isMobile } from 'react-device-detect';

interface VideoChatPermissionsScreenProperties {
  videoTrack: ICameraVideoTrack | null;
  audioTrack: IMicrophoneAudioTrack | null;
  join: () => Promise<void>;
  handleInitialSetting: (value: 'audio' | 'video') => void;
  initialSettings: {
    audio: boolean;
    video: boolean;
  }
  usersOnCallNames: UserOnCallType[];
  permissionsError: boolean;
  changeVideoDevice: (deviceId?: string) => void;
}

export type UserOnCallType = {
  user_id: string;
  user_name: string;
  agora_user_id: string;
}


const VideoChatPermissionsScreen: FC<VideoChatPermissionsScreenProperties> = ({ usersOnCallNames, changeVideoDevice, videoTrack, audioTrack, join, initialSettings, handleInitialSetting, permissionsError }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const setupDeviceList = async () => {
    setCameras(await getCameras());
  }

  useEffect(() => {
    setupDeviceList();
  }, []);

  useEffect(() => {
    if (!videoTrack || !videoRef || !videoRef.current) {
      return;
    }

    videoTrack.play(videoRef.current);

    return () => {
      videoTrack.stop();
    }

  }, [videoTrack, videoRef]);

  /*return <>
    {videoTrack && <video id="local-video-permissions" style={{ width: 200, height: 200 }} ref={videoRef} />}
    {videoTrack && audioTrack && <p>You are all set. Please <u onClick={join}>join the call</u></p>}
    {!videoTrack && <p>No video is present. Please refresh the page</p>}
    {!audioTrack && <p>No audio is present. Please refresh the page</p>}
  </>;*/

  const openMenu = () => {
    if(isMobile) {
      changeVideoDevice();
    } else {
      setIsMenuOpened((prev) => !prev)
    }
  }

  const handleSelect = (value:string) => {
    setIsMenuOpened(false);
    changeVideoDevice(value);
  }

  const names = usersOnCallNames.map(user => user.user_name)

  return <div className={styles.body}>
    <div className={styles.container}>
      <span role="img" aria-label="wave" className={styles.icon}>👍</span>
      <span className={styles.title}>Before This Video Call</span>
      <p className={styles.subTitle}>
        If you have access to WIFI,{'\n'} please use it for better quality.
      </p>
      <span className={styles.onThisCall}>On this video call:</span>
      <span className={styles.subTitle}>{names.join(', ')}</span>
      <div className={styles.cameraContainer}>
        {
          videoTrack && <video id="local-video-permissions" style={{ display: initialSettings.video ? 'block' : 'none' }} className={styles.camera} ref={videoRef} />
        }
        {!initialSettings.video && <span className={styles.cameraOffText}>the camera is turned off</span>}
      </div>
      <div className={styles.buttonsContainer}>
        <div className={styles.btn} onClick={() => handleInitialSetting('audio')}><MicIcon className={`${!initialSettings.audio && styles.iconNotActive}`} /></div>
        <div className={styles.btn} onClick={() => handleInitialSetting('video')}><CamIcon className={`${!initialSettings.video && styles.iconNotActive}`} /></div>
   {/*     <div className={styles.btn} onClick={() => console.log('ChatIcon pressed')}><ChatIcon className={styles.iconNotActive} /></div>
        */}
        <div className={styles.btn} onClick={openMenu}>{isMobile ? <SwitchCamera/> : <SettingsIcon/>}</div>
      </div>
      {(!videoTrack || !audioTrack) && permissionsError && <div className={styles.permissionsError}>
        <span>There was an error accessing your camera and/or microphone</span>
        <br />
        <span>Please refresh the page</span>
      </div>
      }
      <div className={styles.submitBtnContainer}>
        {!permissionsError && <PrimaryButton name={'I\'m Ready'} onClick={join} disabled={!videoTrack || !audioTrack} />}
      </div>
    </div>
    <Modal isOpen={isMenuOpened} onClose={() => setIsMenuOpened(false)}>
      <div className={styles.cameraMenu}>{cameras.map(camera => (
          <div onClick={() => handleSelect(camera.deviceId)} key={camera.deviceId} className={styles.cameraOption}>{camera.label}</div>
      ))}
      </div>
    </Modal>
  </div>
}

export default VideoChatPermissionsScreen;
