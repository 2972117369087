import React from 'react';
import {
  BrowserRouter as Router,
  Route, Routes
} from "react-router-dom";

import MainPage from "./pages/MainPage";
import AdminMainPage from './pages/AdminMainPage';
import UserMainPage from './pages/UserMainPage';
import EndCallPage from "./pages/EndCallPage";


const RootNavigator: React.FC = () => {
  return (
      <Router>
        <Routes>
          <Route path="/users/:user_id/video-chat" Component={UserMainPage} />
          <Route path="/users/:user_id/video-chat/admin" Component={() => <AdminMainPage />} />
          {/*<Route path="/new/users/:user_id/video-chat" Component={UserMainPage} />
          <Route path="/new/users/:user_id/video-chat/admin" Component={AdminMainPage} />*/}
        </Routes>
      </Router>
  );
};

export default RootNavigator;
