import {
  CreatePhoneVerificationDTO,
  VerifyPhoneVerificationDTO,
} from '../../../../iam-service/src/interfaces/dto/phone-verification';
import {
  CreatePhoneVerificationResponse,
  VerifyPhoneVerificationResponse,
} from '../../../../iam-service/src/interfaces/response/phone-verification';
import APIService from './api.service';

import {UserResponse} from '../../../../iam-service/src/interfaces/response/user/user'

type UserVideoChatInfoResponse = {
  userId: string;
  token: string;
  chatId: string;
  appId: string;
  error?: string;
}

type AuthResponce = {
  access_token: string,
  refresh_token: string,
  errors?: string,
}

type UserName = { first_name: string, last_name: string }
export async function createPhoneVerification(
  data: CreatePhoneVerificationDTO,
): Promise<CreatePhoneVerificationResponse> {
  return APIService.post<CreatePhoneVerificationResponse>(
    '/v1/phone_verifications',
    data,
  );
}

export async function sendNameAndId(
    user_id: string,
    body: {}
): Promise<UserVideoChatInfoResponse> {
  return APIService.post<UserVideoChatInfoResponse>(
    `/v1/users/${user_id}/video_chat`,
      body
  );
}

export async function getName(user_id:string): Promise<UserName> {
  return APIService.get<UserName>(
    `/v1/users/${user_id}/name`);
}

export async function authGetTokens(
    user_id: string,
    body: { name?: string, phone_number?: string, secret?: string}
): Promise<AuthResponce> {
  return APIService.post<AuthResponce>(
    `/v1/auth/users/${user_id}`,
      body
  );
}

