import React, { useState, useEffect } from 'react';
import './styles.scss'; // Assuming you have a CSS file for the styles

const AnimatedErrorMsg = ({ text }: {text: string | undefined}) => {
    const [visible, setVisible] = useState(!!text);

    useEffect(() => {
        if (!text) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    }, [text]);

    return (
        <div className={`animated-error-msg ${visible ? 'visible' : ''}`}>
            {text}
        </div>
    );
};

export default AnimatedErrorMsg;
