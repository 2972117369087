import axios, {AxiosRequestConfig} from 'axios';

export const SERVER_URL = 'http://127.0.0.1:3002';
//export const API_URL = `${SERVER_URL}/api`;
export const API_URL = `https://timleyapp.ru/api`;

interface IAPIServiceParameters {
  apiURL?: string;
}

export default class APIService {
  public static token: string = '';

  public static setToken(token: string | null) {
    if (!token) {
      return;
    }

    this.token = token;
  }

  public static async get<RESPONSE = any>(
    url: string,
    query: any = {},
    parameters: IAPIServiceParameters = {},
  ): Promise<RESPONSE> {
    let urlParams = '';

    if (Object.keys(query).length) {
      urlParams = `?${new URLSearchParams(query).toString()}`;
    }

    console.log('headers', this.getHeaders());

    try {
      const data = await this.makeRequest({
        method: 'get',
        url: `${this.getURL(parameters)}${url}${urlParams}`,
        headers: await this.getHeaders(),
      });

      return data;
    } catch (e) {
      console.log('e', e);

      throw e;
    }
  }

  public static async post<RESPONSE = any, DATA = any>(
    url: string,
    body: DATA,
    parameters: IAPIServiceParameters = {},
  ): Promise<RESPONSE> {
    return this.makeRequest({
      method: 'post',
      url: `${this.getURL(parameters)}${url}`,
      data: body,
      headers: await this.getHeaders(),
    });
  }

  public static async put<RESPONSE = any, DATA = any>(
    url: string,
    body: DATA,
    parameters: IAPIServiceParameters = {},
  ): Promise<RESPONSE> {
    return this.makeRequest({
      method: 'put',
      url: `${this.getURL(parameters)}${url}`,
      data: body,
      headers: await this.getHeaders(),
    });
  }

  public static async patch<RESPONSE = any, DATA = any>(
    url: string,
    body: DATA,
    parameters: IAPIServiceParameters = {},
  ): Promise<RESPONSE> {
    return this.makeRequest({
      method: 'patch',
      url: `${this.getURL(parameters)}${url}`,
      data: body,
      headers: await this.getHeaders(),
    });
  }

  public static async delete<RESPONSE = any, DATA = any>(
    url: string,
    body?: DATA,
    parameters: IAPIServiceParameters = {},
  ): Promise<RESPONSE> {
    return this.makeRequest({
      method: 'delete',
      url: `${this.getURL(parameters)}${url}`,
      data: body,
      headers: await this.getHeaders(),
    });
  }

  private static async makeRequest(params: AxiosRequestConfig) {
    try {
      const data = await axios(params);
      return data.data;
    } catch (e) {
      // if (e instanceof Error) {
      //   if (e.message.indexOf('401') !== -1) {
      //     throw new NotAuthorized(e.message);
      //   }

      //   if (e.message.indexOf('422') !== -1) {
      //     throw new UnprocessableEntity(e.message);
      //   }

      //   if (e.message.indexOf('404') !== -1) {
      //     throw new NotFound(e.message);
      //   }
      // }

      throw e;
    }
  }

  private static getHeaders() {
    const headers: any = {
      'Content-Type': 'application/json'
    };

    if (this.token) {
      headers['Authorization'] = `Bearer ${this.token}`;
    }

    return headers;
  }

  private static getURL(parameters: IAPIServiceParameters) {
    return parameters.apiURL === undefined || parameters.apiURL === null
      ? API_URL
      : parameters.apiURL;
  }
}
