import React from 'react';
import styles from './BlueButton.module.scss';

type BlueButtonPropsType = {
    name: string;
    onClick: () => void;
    btnStyle?: React.CSSProperties;
    isLoading?: boolean;
    disabled?: boolean;
    isCancel?: boolean;
};

const PrimaryButton = ({
                        name,
                        onClick,
                        btnStyle,
                        isLoading,
                        disabled, isCancel
                    }: BlueButtonPropsType) => {
    return (
        <button
            style={btnStyle}
            disabled={disabled || isLoading}
            onClick={onClick}
            className={`${styles.blueButton} ${isCancel && styles.cancel}`}>
            {isLoading ? (
                <div className="loader"></div> // Custom spinner or use a third-party library
            ) : (
                <span>{name}</span>
            )}
        </button>
    );
};

export default PrimaryButton;
