import React, {useState} from "react";
import styles from "./styles.module.scss";
import {useAppSelector} from "../../utils/reduxHooks";
import moment from "moment";
import {ReactComponent as SelectedEmojiIcon} from "../../assets/svgIcons/selectedEmojiIcon.svg";
import {PrimaryButton} from "../../components";
const emojiMarks = [
    {
        value: 'Happy',
        emoji: '\u{1F600}',
    },
    {
        value: 'Proud',
        emoji: '\u{1F60A}',
    },
    {
        value: 'Energized',
        emoji: '\u{1F929}',
    },
    {
        value: 'Relaxed',
        emoji: '\u{1F60C}',
    },
    {
        value: 'Grateful',
        emoji: '\u{1F642}',
    },
    {
        value: 'Concerned',
        emoji: '\u{1F612}',
    }
];

const EndCallPage = () => {
    const {remoteUserName, localUserName, endCallTime, startCallTime} = useAppSelector(state => state.mainSlice);
    const [selectedValues, setSelectedValue] = useState<string[]>([]);
    const [isGradeSet, setIsGradeSet] = useState(true);
    let callDuration
    if(endCallTime && startCallTime) {
        callDuration = Math.abs(moment(endCallTime).valueOf() - moment(startCallTime).valueOf())
    }

    const handleEmojiClick = (value: string) => {
        if(selectedValues.includes(value)) {
            const filteredValues = selectedValues.filter((selectedValue) => selectedValue !== value)
            setSelectedValue(filteredValues);
        } else {
            setSelectedValue([...selectedValues, value])
        }
    }

    return <div className={styles.body}>
        <div className={styles.container}>
        <span role="img" aria-label="wave" className={styles.icon}>👋</span>
            {isGradeSet ? <h1 className={styles.title}>You've Left The Call</h1>: <h1 className={styles.title}>How did the video call
                with James go?</h1>
            }
            {isGradeSet ?   <span className={styles.subTitle}>
                Here is a call summary:
            </span>:<span className={styles.subTitle}>
                You can choose more than one.
            </span>}
            {isGradeSet ? <div className={styles.table}>
                <div className={styles.tableRow}>
                    <div className={styles.tableName}>Organizer:</div>
                    <div className={styles.tableValue}>{remoteUserName}</div>
                </div>
                <div className={styles.tableRow}>
                    <div className={styles.tableName}>Member:</div>
                    <div>{localUserName}</div>
                </div>
                <div className={styles.tableRow}>
                    <div className={styles.tableName}>When:</div>
                    <div>{moment(startCallTime).format('dddd, MMM D [at] h:mm A')}</div>
                </div>
                <div className={`${styles.tableRow} ${styles.lastRow}`}>
                    <div className={styles.tableName}>Call Duration:</div>
                    <div>{moment(callDuration).format('m')} minutes</div>
                </div>
            </div> : <div className={styles.emojiBlock}>
                {emojiMarks.map(item =><div className={styles.markItem} onClick={() => handleEmojiClick(item.value)}>
                    <div className={`${styles.emojiContainer} ${selectedValues.includes(item.value) && styles.emojiSelected}`}>
                        <span className={styles.emoji}>{item.emoji}</span>
                        {selectedValues.includes(item.value) && <div className={styles.selectedIcon}><SelectedEmojiIcon/></div>}
                    </div>
                    <span className={styles.emojiName}>{item.value}</span>
                </div> )}
            </div>}
            {
               isGradeSet ? <p className={styles.subTitle}>
                   Have a wonderful day!
               </p>:<PrimaryButton btnStyle={{marginBottom: 10}} name={'Continue'} onClick={() =>(setIsGradeSet(true))}/>
            }
</div>
</div>
}

export default EndCallPage;
