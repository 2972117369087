import React, { FC, useEffect, useRef, useState } from 'react';
import {
  createCameraVideoTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  UID
} from 'agora-rtc-sdk-ng/esm';
import styles from "../AgoraVideoChat/styles.module.css";
import placeHolderNoPhoto from "../../assets/noPhotoPlaceHolder.png";
import placeHolderPhoto from "../../assets/Background.png";
import {ReactComponent as MutedCamera} from "../../assets/svgIcons/videoChatControlsIcon/MutedCameraIcon.svg";
import {ReactComponent as MutedMicrophone} from "../../assets/svgIcons/videoChatControlsIcon/MutedMicIcon.svg";
import {setEndCallTime, setIsAudioOn, setIsVideoOn} from "../../store/features/main/mainSlice";
import {ReactComponent as MicIcon} from "../../assets/svgIcons/videoChatControlsIcon/MicIcon.svg";
import {ReactComponent as CameraIcon} from "../../assets/svgIcons/videoChatControlsIcon/CameraIcon.svg";
import {ReactComponent as ChatIcon} from "../../assets/svgIcons/videoChatControlsIcon/ChatIcon.svg";
import {ReactComponent as SwitchCamera} from "../../assets/svgIcons/videoChatControlsIcon/switchCameraIcon.svg";
import {ReactComponent as PhoneIcon} from "../../assets/svgIcons/videoChatControlsIcon/PhoneIcon.svg";
import {ReactComponent as LocalMutedMicIcon} from "../../assets/svgIcons/videoChatControlsIcon/localMuteMicIcon.svg";
import {ReactComponent as LocalMutedVideoIcon} from "../../assets/svgIcons/videoChatControlsIcon/localMuteVideoIcon.svg";
import {ReactComponent as RemoteMuteMicIcon} from "../../assets/svgIcons/videoChatControlsIcon/remoteMicMutedIcon.svg";
import {Modal, PrimaryButton} from "../index";
import {ReactComponent as AreUSureIcon} from "../../assets/svgIcons/AreUSureIcon.svg";
import {useAppDispatch, useAppSelector} from "../../utils/reduxHooks";
import {useNavigate} from "react-router-dom";
import {isMobile} from "react-device-detect";

interface VideoChatScreenProperties {
  user_id?: string,
  client: IAgoraRTCClient;
  videoTrack: ICameraVideoTrack;
  audioTrack: IMicrophoneAudioTrack;
  usersOnCall: UID[];
  initialSettings: {
    audio: boolean;
    video: boolean;
  };
  endCall: () => void,
  localName: string;
  changeVideoDevice: (value? :string) => void
}

const VideoChatScreen: FC<VideoChatScreenProperties> = ({endCall,changeVideoDevice, user_id, client, videoTrack, audioTrack, usersOnCall, initialSettings, localName }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const remoteVideoRef = useRef<HTMLVideoElement | null>(null);
  const [isLocalAudioMuted, setIsLocalAudioMuted] = useState<boolean>(!initialSettings.audio);
  const [isLocalVideoMuted, setIsLocalVideoMuted] = useState<boolean>(!initialSettings.video);
  const [isRemoteVideoSubed, setIsRemoteVideoSubed] = useState<boolean>(false);
  const [isRemoteAudioSubed, setIsRemoteAudioSubed] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {remoteUserName} = useAppSelector(state => state.mainSlice);
  const onUserPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio"
  ) => {
    if (!client) {
      return;
    }

    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play(remoteVideoRef.current!);
      setIsRemoteVideoSubed(true);
    }

    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);

      console.log('remote track', remoteTrack);

      remoteTrack.play();
      setIsRemoteAudioSubed(true);
    }
  }

  const onUserUnPublish = async (
    user: IAgoraRTCRemoteUser,
    mediaType: "video" | "audio"
  ) => {
    if (mediaType === "video") {
      await client.unsubscribe(user, mediaType);
      setIsRemoteVideoSubed(false);
    }
    if (mediaType === "audio") {
      await client.unsubscribe(user, mediaType);
      setIsRemoteAudioSubed(false);
    }
  }

  const switchAudio = (isMuted: boolean) => {
    if (!client || !audioTrack) {
      return;
    }

    setIsLocalAudioMuted(isMuted);

    if (isMuted) {
      client.unpublish(audioTrack);
    } else {
      client.publish(audioTrack);
    }
  }

  const switchVideo = async (isMuted: boolean) => {
    if (!client || !videoTrack) {
      return;
    }

    setIsLocalVideoMuted(isMuted);

    if (isMuted) {
      await client.unpublish(videoTrack);
      await videoTrack.setEnabled(false)
    } else {
      await videoTrack.setEnabled(true)
      videoTrack.play(usersOnCall.length ? videoRef.current! : remoteVideoRef.current!)
      await client.publish(videoTrack);
    }
  }




  useEffect(() => {
    if (!videoTrack || !remoteVideoRef || !remoteVideoRef.current || isLocalVideoMuted) {
      return;
    }

    if(usersOnCall.length && (!videoRef || !videoRef.current)) {
      return ;
    }

    videoTrack.play(usersOnCall.length ? videoRef.current! : remoteVideoRef.current!);

    client.publish(videoTrack);

  }, [videoTrack, videoRef, usersOnCall]);

  useEffect(() => {
    if (!audioTrack || !client || isLocalAudioMuted) {
      return;
    }

    client.publish(audioTrack);

  }, [audioTrack, client]);

  useEffect(() => {
    if (!remoteVideoRef || !remoteVideoRef.current || !client) {
      return;
    }

    client.on('user-published', onUserPublish);
    client.on('user-unpublished', onUserUnPublish);

    /*return () => {
      client.off('user-published', onUserPublish)
      client.off('user-unpublished', onUserUnPublish);
    }*/
  }, [remoteVideoRef, client]);

  const leave = async () => {
    await videoTrack.setEnabled(false);
    await audioTrack.setEnabled(false)
    await client.unpublish(audioTrack);
    await client.unpublish(videoTrack);
    await client.leave();
    endCall()
  }

  /*return <div>
    {videoTrack && <div>
      <p>Myself</p>
      <div style={{ display: 'flex' }}>
        <button style={{ marginRight: 10 }} onClick={() => switchVideo(!isLocalVideoMuted)}>{isLocalVideoMuted ? 'Enable' : 'Disable'} video</button>
        <button onClick={() => switchAudio(!isLocalAudioMuted)}>{isLocalAudioMuted ? 'Enable' : 'Disable'} audio</button>
      </div>
      <video ref={videoRef} style={{ width: 200, height: 200, display: isLocalVideoMuted ? 'none' : 'block' }} />
      {isLocalVideoMuted && <div style={{ width: 200, height: 200, background: 'black' }} />}
    </div>}

    <div>
      <p>Other user</p>
      {isRemoteAudioMuted && <p>Audio muted</p>}
      <video ref={remoteVideoRef} style={{ width: 200, height: 200, display: isRemoteVideoMuted ? 'none' : 'block' }} />
      {isRemoteVideoMuted && <div style={{ width: 200, height: 200, background: 'black' }} />}
    </div>
  </div>;*/

  return <div className={styles.body}>
      <div className={styles.bigVideo}>
      {!isRemoteVideoSubed && !!usersOnCall.length && <div className={styles.noVideo}>
    <img className={styles.bigNoVideoImg} src={placeHolderNoPhoto} />
        <div className={styles.remoteNameContainer}>
          <div className={styles.bigName}>{remoteUserName}</div> {!isRemoteAudioSubed && <RemoteMuteMicIcon/> }
        </div>

  </div>}
  {
    isLocalVideoMuted && !usersOnCall.length && <div className={styles.noVideo}>
      <img className={styles.bigNoVideoImg} src={placeHolderNoPhoto} />
        <div className={styles.remoteNameContainer}>
          <div className={styles.bigName}>{localName}</div>
        </div>
    </div>
  }
  <video ref={remoteVideoRef}  id={"remote-video"} style={{ borderRadius: 5, width: '100%', height: '100%', display: (isRemoteVideoSubed || (!usersOnCall.length && !isLocalVideoMuted) ? 'block' : 'none') }} />
  {isRemoteVideoSubed && <div className={styles.remoteNameContainer}>
    <div className={styles.bigName}>{remoteUserName}</div> {!isRemoteAudioSubed && <RemoteMuteMicIcon/> }
  </div>}
</div>
  {
    !!usersOnCall.length && <div className={styles.smallVideo}>
      {isLocalVideoMuted && <div className={styles.smallNoVideo}>
        <img className={styles.smallNoVideoImg} src={placeHolderNoPhoto} />
      </div>}
      <video ref={videoRef} id='camera-video' style={{ borderRadius: 5, width: '100%', height: '100%', display: !isLocalVideoMuted ? 'block' : 'none' }} />
      <div className={styles.smallFooter}>
        <div className={styles.smallName}>{localName}</div>
      </div>
    </div>
  }
  <div className={styles.controls}>
    <button className={`${styles.btn} ${isLocalAudioMuted && styles.disabled}`} onClick={() => switchAudio(!isLocalAudioMuted)}>{isLocalAudioMuted ? <LocalMutedMicIcon/> : <MicIcon />}</button>
    <button className={`${styles.btn} ${isLocalVideoMuted && styles.disabled}`} onClick={() => switchVideo(!isLocalVideoMuted)}>{isLocalVideoMuted ? <LocalMutedVideoIcon /> : <CameraIcon />}</button>
    {isMobile && <button className={styles.btn} onClick={() => changeVideoDevice()}><SwitchCamera /></button>}
   {/* <button className={styles.btn} disabled={true}><ChatIcon /></button>*/}
    <button className={styles.btn} onClick={() => setIsModalOpen(true)}><PhoneIcon /></button>
  </div>
  <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
    <div className={styles.modal}>
        <AreUSureIcon />
        <div className={styles.modalTitle}>Are you sure you want to<br />leave this video call?</div>
        <div className={styles.btnContainer}>
          <PrimaryButton onClick={() => setIsModalOpen(false)} name={"Cancel"}/>
          <PrimaryButton isCancel onClick={leave}  name={'Leave Call'}></PrimaryButton>
        </div>
    </div>
  </Modal>
</div>
}

export default VideoChatScreen;
