import { FC, useEffect, useState } from 'react';
import APIService from '../../services/api/api.service';
import { useParams } from 'react-router-dom';
import VideoChatScreen from './VideoChatScreen';
import VideoChatPermissionsScreen, { UserOnCallType } from './PermissionsScreen';
import {
  createClient,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  UID
} from 'agora-rtc-sdk-ng/esm';
import { IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng/esm';
import DevicesList from './DevicesList';
import {useAppDispatch} from "../../utils/reduxHooks";
import {
  setEndCallTime,
  setLocalUserName,
  setRemoveUserName,
  setStartCallTime
} from "../../store/features/main/mainSlice";
import EndCallPage from "../../pages/EndCallPage";
import {isMobile} from "react-device-detect";
import {ScreenVideoTrackInitConfig} from "agora-rtc-sdk-ng";

interface VideoChatInitialScreenProperties {
  user: any;
}

const VideoChatInitialScreen: FC<VideoChatInitialScreenProperties> = ({ user }) => {
  // get an agora token +
  // join the channel
  // setup the video
  const dispatch = useAppDispatch();
  const { user_id } = useParams();
  const [hasPermissions, setHasPermissions] = useState<boolean>(false);
  const [agoraCredentials, setAgoraCredentials] = useState<any>(null);
  const [client, setClient] = useState<IAgoraRTCClient | null>(null);
  const [videoTrack, setVideoTrack] = useState<ICameraVideoTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<IMicrophoneAudioTrack | null>(null);
  const [videoTrackError, setVideoTrackError] = useState<boolean>(false);
  const [audioTrackError, setAudioTrackError] = useState<boolean>(false);
  const [usersOnCall, setUsersOnCall] = useState<UID[]>([]);
  const [initialSettings, setInitialSettings] = useState({ video: true, audio: true })
  const [usersOnCallNames, setUsersOnCallNames] = useState<UserOnCallType[]>([])
  const [permissionsError, setPermissionsError] = useState<boolean>(false);
  const [facingMode, setFacingMOde] = useState<VideoFacingModeEnum>('user')
  const [isCallEnded, setIsCallEnded] = useState(false);
  useEffect(() => {
    APIService.get<UserOnCallType[]>(`/v1/users/${user_id}/video_chat/participants`).then(res => {
      setUsersOnCallNames(res);
      res.forEach((userOnCall) => {
        if (userOnCall.user_id !== user_id) {
          dispatch(setRemoveUserName(userOnCall.user_name))
        } else {
          dispatch(setLocalUserName(userOnCall.user_name));
        }
      })
    })
  }, [usersOnCall])


  const getToken = async () => {
    const token = await APIService.post(`/v1/users/${user_id}/video_chat`, {});
    setAgoraCredentials(token);

    return token;
  }

  const createAgoraClient = () => {
    const client: IAgoraRTCClient = createClient({
      mode: "rtc",
      codec: "vp8",
    });

    setClient(client);

    return client;
  }

  const createTracks = async () => {
    if (!videoTrack) {
      try {
        const videoTrack = await createCameraVideoTrack();
        setVideoTrack(videoTrack);
      } catch (e) {
        setVideoTrackError(true);
      }
    }

    if (!audioTrack) {
      try {
        const audioTrack = await createMicrophoneAudioTrack();
        setAudioTrack(audioTrack);
      } catch (e) {
        setAudioTrackError(true);
      }
    }
  }

  const updateQuality = async () => {
    if(videoTrack) {
      await videoTrack.setEncoderConfiguration('120p_1')
    }
  }

  const getPermissions = async () => {
    setPermissionsError(false);

    try {
      const getUserMedia = navigator.mediaDevices?.getUserMedia || (navigator as any).getUserMedia || (navigator as any).webkitGetUserMedia || (navigator as any).mozGetUserMedia;

      const res = await getUserMedia.call(navigator.mediaDevices || navigator, { video: true, audio: true });
    } catch (e) {
      setPermissionsError(true);
    }
  }

  const setup = async () => {
    const token = await getToken();
    const client = createAgoraClient();

    await getPermissions();

    await createTracks();
  }

  const join = async () => {
    if (!client || !agoraCredentials) {
      return;
    }

    await client.join(
      agoraCredentials.appId,
      agoraCredentials.chatId,
      agoraCredentials.token,
      agoraCredentials.userId,
    );

    setUsersOnCall(client.remoteUsers.map(item => item.uid))

    client.on('user-joined', (user) => {
      setUsersOnCall((prev) => [...prev, user.uid]);
    });

    client.on('user-left', (userLeft) => {
      setUsersOnCall(usersOnCall.filter(uid => uid !== userLeft.uid))
    })
    client.on("token-privilege-will-expire", async function(){
      // After requesting a new token
      const updatedToken = await getToken();
      await client.renewToken(updatedToken);
    });
    dispatch(setStartCallTime(new Date()))
    setHasPermissions(true);

  }

  const changeVideoDevice = async (deviceId?: string ) => {
    if (!videoTrack) {
      console.log('no video track');
      return;
    }
    console.log('facingMode', facingMode);
    if(!deviceId) {
      if(facingMode === 'user') {
        await videoTrack.setDevice({facingMode: 'environment'});
        setFacingMOde('environment');
      } else {
        await videoTrack.setDevice({facingMode: 'user'});
        setFacingMOde('user')
      }
      return
    }

    videoTrack.setDevice(deviceId);
  }

  const changeAudioDevice = async (deviceId: string) => {
    if (!audioTrack) {
      console.log('no video track');
      return;
    }

    audioTrack.setDevice(deviceId);
  }

  useEffect(() => {
    setup();
  }, []);

  const handleInitialSetting = (btnName: 'video' | 'audio') => {
    setInitialSettings((prev) => ({ ...prev, [btnName]: !prev[btnName] }))
  }

  const endCall = () => {
    dispatch(setEndCallTime(new Date()))
    setIsCallEnded(true);
  };

  if(isCallEnded) {
    return <EndCallPage/>
  }

  return <div>
    {!hasPermissions && <VideoChatPermissionsScreen
      usersOnCallNames={usersOnCallNames}
      videoTrack={videoTrack}
      audioTrack={audioTrack}
      join={join}
      handleInitialSetting={handleInitialSetting}
      initialSettings={initialSettings}
      permissionsError={permissionsError}
      changeVideoDevice={changeVideoDevice}
    />}
    {videoTrackError && <p>Cannot create video track. Your call will be without a video</p>}
    {audioTrackError && <p>Cannot create audio track. Your call will be without an audio</p>}
    {/* <DevicesList onVideoDeviceChange={changeVideoDevice} onAudioDeviceChange={changeAudioDevice} />*/}
    {hasPermissions && client && agoraCredentials && videoTrack && audioTrack && <VideoChatScreen changeVideoDevice={changeVideoDevice} endCall={endCall} user_id={user_id} localName={user.first_name} usersOnCall={usersOnCall} client={client} videoTrack={videoTrack} audioTrack={audioTrack} initialSettings={initialSettings} />}
  </div>;
}

export default VideoChatInitialScreen;
