import { FC, useEffect, useState } from 'react';
import { checkAndSaveToken, getToken, getZohoAuth } from '../../services/api/login.service';
import APIService from '../../services/api/api.service';
import { useSearchParams } from 'react-router-dom';
import VideoChatInitialScreen from '../../components/NewAgoraVideoChat/InitialScreen';

const AdminMainPage: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<any>(null);

  const doAuth = async () => {
    const zohoAuth = await getZohoAuth();

    if (zohoAuth.authUrl) {
      window.location.href = zohoAuth.authUrl;
    }
  };

  const checkAuth = async () => {
    const token = await getToken('admin');

    if (!token) {
      doAuth();
    }

    APIService.setToken(token);
  }

  const login = async () => {
    const hasTokenInQueryString = checkAndSaveToken('admin');
    await checkAuth();
    if (hasTokenInQueryString) {
      searchParams.delete('access_token');
      searchParams.delete('refresh_token');

      setSearchParams(searchParams);
    }

    const user = await APIService.get('/v1/users/me/');

    setUser(user);
  }

  useEffect(() => {
    login();
  }, []);

  return <>
    {!user && <span>Loading</span>}
    {user && <VideoChatInitialScreen user={user} />}
  </>;
}

export default AdminMainPage;
