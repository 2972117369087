import { JwtPayload, jwtDecode } from 'jwt-decode';
import APIService from './api.service';

type TokenType = 'admin' | 'user';

export function updateTokens(accessToken: string, refreshToken: string, tokenType: TokenType) {
  localStorage.setItem(getTokenNameByTokenType('access_token', tokenType), accessToken);
  localStorage.setItem(getTokenNameByTokenType('refresh_token', tokenType), refreshToken);
}

export async function getZohoAuth() {
  return APIService.get<{ authUrl: string }>('/v1/auth/zoho/', { url: window.location.href });
}

export function checkAndSaveToken(tokenType: TokenType = 'user') {
  const query = new URLSearchParams(window.location.search);

  if (query.get('access_token') && query.get('refresh_token')) {
    updateTokens(
      query.get('access_token') as string,
      query.get('refresh_token') as string,
      tokenType,
    );

    return true;
  }

  return false;
}

function getTokenNameByTokenType(tokenName: string, tokenType: TokenType = 'user') {
  return `${tokenType === 'admin' ? 'admin_' : ''}${tokenName}`;
}

export async function getToken(tokenType: TokenType = 'user') {
  const token = localStorage.getItem(getTokenNameByTokenType('access_token', tokenType));

  if (!token) {
    return null;
  }

  let decoded: JwtPayload;

  try {
    decoded = jwtDecode(token);
  } catch (e) {
    console.error(e);

    return null;
  }


  if ((decoded.exp as number) * 1000 < new Date().getTime()) {
    const refreshToken = localStorage.getItem(getTokenNameByTokenType('refresh_token', tokenType));

    if (!refreshToken) {
      return null;
    }

    try {
      const refresh = await APIService.post<{ access_token: string, refresh_token: string }>(`/v1/auth/refresh?token=${refreshToken}`, {});
      updateTokens(refresh.access_token, refresh.refresh_token, tokenType);

      return refresh.access_token;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return token;
}

export function refreshToken() { }
