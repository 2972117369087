import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {IAgoraRTCRemoteUser, UID} from "agora-rtc-sdk-ng/esm";

export type MainState = {
  isLoggedIn: boolean,
  isJoined: boolean,
  isJoining: boolean,
  usersOnCall: UID[],
  isVideoSubed: boolean,
  isRemoteVideoMuted: boolean,
  isRemoteAudioMuted: boolean,
  isLeaving: boolean,
  isAudioPubed: boolean,
  isVideoPubed: boolean,
  isVideoOn: boolean,
  isAudioOn: boolean,
  isCallRendered: boolean,
  localUserName: string | null,
  remoteUserName: string | null,
  startCallTime: Date | null,
  endCallTime: Date | null,
};

const initialState: MainState = {
  isLoggedIn: false,
  usersOnCall: [],
  isAudioPubed: false,
  isLeaving: false,
  isJoined: false,
  isJoining: false,
  isVideoSubed: false,
  isRemoteVideoMuted: true,
  isRemoteAudioMuted: true,
  isVideoPubed: false,
  isAudioOn: true,
  isVideoOn: true,
  isCallRendered: false,
  localUserName: null,
  remoteUserName: null,
  startCallTime: null,
  endCallTime: null,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setIsAudioPubed: (state, action: PayloadAction<boolean>) => {
      state.isAudioPubed = action.payload;
    },
    setIsJoined: (state, action: PayloadAction<boolean>) => {
      state.isJoined = action.payload;
    },
    setIsJoining: (state, action: PayloadAction<boolean>) => {
      state.isJoining = action.payload;
    },
    setIsVideoSubed: (state, action: PayloadAction<boolean>) => {
      state.isVideoSubed = action.payload;
    },
    setIsRemoteVideoMuted: (state, action: PayloadAction<boolean>) => {
      state.isRemoteVideoMuted = action.payload;
    },
    setIsVideoPubed: (state, action: PayloadAction<boolean>) => {
      state.isVideoPubed = action.payload;
    },
    setUsersOnCall: (state, action: PayloadAction<UID[]>) => {
      state.usersOnCall.push(...action.payload)
    },
    setIsLeaving: (state, action: PayloadAction<boolean>) => {
      state.isLeaving = action.payload;
    },
    setIsRemoteAudioMuted: (state, action: PayloadAction<boolean>) => {
      state.isRemoteAudioMuted = action.payload;
    },
    setIsVideoOn: (state, action: PayloadAction<boolean>) => {
      state.isVideoOn = action.payload;
    },
    setIsAudioOn: (state, action: PayloadAction<boolean>) => {
      state.isAudioOn = action.payload;
    },
    setIsCallRendered: (state, action: PayloadAction<boolean>) => {
      state.isCallRendered = action.payload;
    },
    setLocalUserName: (state, action: PayloadAction<string>) => {
      state.localUserName = action.payload
  },
    setRemoveUserName: (state, action: PayloadAction<string>) => {
      state.remoteUserName = action.payload
    },
    setStartCallTime: (state, action: PayloadAction<Date>) => {
      state.startCallTime = action.payload
    },
    setEndCallTime: (state, action: PayloadAction<Date>) => {
      state.endCallTime = action.payload
    }
  },
});

export const {
  setLocalUserName,
  setRemoveUserName,
  setStartCallTime,
  setEndCallTime,
  setIsLoggedIn,
  setUsersOnCall,
  setIsAudioPubed,
  setIsLeaving,
  setIsJoined,
  setIsJoining,
  setIsVideoSubed,
  setIsRemoteVideoMuted,
  setIsRemoteAudioMuted,
  setIsVideoPubed,
  setIsVideoOn,
  setIsAudioOn,
  setIsCallRendered,
} = mainSlice.actions;

export default mainSlice.reducer;
