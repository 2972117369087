import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../utils/reduxHooks';
import { checkAndSaveToken, getToken, getZohoAuth } from '../../services/api/login.service';
import APIService from '../../services/api/api.service';
import { useSearchParams } from 'react-router-dom';

import VideoChatInitialScreen from '../../components/NewAgoraVideoChat/InitialScreen';
import UserAuth from "../NewUserAuthPage";

const UserMainPage: FC = () => {
  const [user, setUser] = useState<any>(null);
  const [showAuth, setShowAuth] = useState<boolean>(false);

  const checkAuth = async () => {
    const token = await getToken();

    if (!token) {
      setShowAuth(true);
      return false;
    }

    APIService.setToken(token);

    return true;
  }

  const login = async () => {
    const isLoggedIn = await checkAuth();

    if (!isLoggedIn) {
      return;
    }

    const user = await APIService.get('/v1/users/me/');

    setUser(user);
  }

  useEffect(() => {
    login();
  }, [])

  return <>
    {!user && showAuth && <UserAuth onSuccessAuth={login}/>}

    {user && <VideoChatInitialScreen user={user} />}
  </>;
}

export default UserMainPage;
